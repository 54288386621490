import React from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";

const SEO = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          image
        }
      }
    }
  `);

  return (
    <Helmet>
      <title>{data.site.siteMetadata.title}</title>
      <meta
        name="title"
        property="og:title"
        content={data.site.siteMetadata.title}
      />
      <meta
        name="image"
        property="og:image"
        content={data.site.siteMetadata.image}
      />
      <meta name="type" property="og:type" content="website" />
      <meta
        name="description"
        property="og:description"
        content={data.site.siteMetadata.description}
      />
    </Helmet>
  );
};

export default SEO;
